<template>
  <td class="badge-cell" @click.prevent="$emit('clicked', item)">
    <v-layout justify-center>
      <BooksyBadge :icon="icon" :fontColor="fontColor" :color="color">{{
        text
      }}</BooksyBadge>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
    value: {
        
    },
  },
  computed: {
    text() {
      return this.value ? "TAK" : "NIE";
    },
    icon() {
      return this.value ? "" : "";
    },
    fontColor() {
      return this.value ? "#00a34b" : "#de003b";
    },
    color() {
      return this.value ? "#e2f6ea" : "#ffebeb";
    },
  },
  components: {
    BooksyBadge: () => import("@/components/buttons/BooksyBadge"),
  },
};
</script>

<style scoped>
.badge-cell {
  cursor: pointer;
}
</style>